

















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { SystemResourcesEntityModel, SystemResourcesQueryModel } from '@common-src/entity-model/system-resources-entity';
import SystemResourcesService from '@common-src/service3/system-resources.service';

@Component
export default class SystemResourcesListComponent extends TableDialogFormComponent<SystemResourcesEntityModel, SystemResourcesQueryModel> {
    SystemResourcesEntityModel = SystemResourcesEntityModel;
    created() {
        this.initTable({
            service: new SystemResourcesService(),
            queryModel: new SystemResourcesQueryModel(),
            tableColumns: SystemResourcesEntityModel.getTableColumns()
        });
        this.getList();
    }
}

